/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { bool as boolType, any, string as stringType, number as numType } from 'prop-types';
import { Table, TableCell, TableRow, TableBody } from '@one-thd/sui-atomic-components';
import './feature-based-recommendations.scss';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { FbrProductPod } from './FbrProductPod';

const FBRTableView = (props) => {
  const {
    data, tableData, storeId, membershipInformation, onClick, onHover, currentItemIndex,
    zipCode, itemId, recommendedFeatureSet, recommendedProductSet, scheme
  } = props;

  let displayProducts = data;
  const defaultVariables = {
    itemId,
    storeId,
    zipCode,
    installationServices: false,
    loyaltyMembershipInput: membershipInformation?.data?.loyaltyMembership || null,
    dataSource: 'FBR'
  };
  return (
    <>
      <QueryProvider cacheKey="fbr-table-view-cache" defaultVariables={defaultVariables}>
        {displayProducts?.length && (
          <>
            <div className="sui-w-full sui-overflow-y-auto sui-no-scrollbar fbr-table-view">
              <Table layout="fixed" unlined>
                <TableBody>
                  <TableRow key={1}>
                    {displayProducts?.map((fbrItem, idx) => {
                      const { product, recommendedFeatures } = fbrItem;
                      const { itemId: productItemId } = product;
                      const { preferredPriceFlag } = product?.pricing || {};
                      const cellProps = idx === 0 ? { sticky: 'horizontal' } : {};
                      return (
                        <TableCell
                          key={`fbr-product-pod-cell-${idx}`}
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...cellProps}
                          className={idx === currentItemIndex ? 'current-pod' : ''}
                          width={200}
                        >
                          {idx === currentItemIndex && <div className="fbr__current-banner">current item</div>}
                          <QueryProvider cacheKey="fbr-product-pod-cache" defaultVariables={defaultVariables}>

                            <FbrProductPod
                              current={idx === currentItemIndex}
                              product={product}
                              onclick={onClick}
                              onHover={onHover}
                              fbrItem={fbrItem}
                              data={data}
                              parent="feature-based-recommendations"
                              recommendedFeatures={recommendedFeatures}
                              itemId={productItemId}
                              key={productItemId}
                              position={idx}
                              storeId={storeId}
                              scheme={scheme}
                              preferredPriceFlag={preferredPriceFlag}
                              recommendedFeatureSet={recommendedFeatureSet}
                              recommendedProductSet={recommendedProductSet}
                            />
                          </QueryProvider>

                        </TableCell>
                      );
                    })}

                  </TableRow>
                  {Object.keys(tableData)?.map((tableKey, index) => {
                    return index <= 4 && (
                      <React.Fragment key={`row-main-${tableKey}`}>
                        <TableRow key={`row-label-${tableKey}`} className="label-row">
                          {displayProducts?.map((productDetail, productIndex) => {
                            const cellProps = productIndex === 0 ? { width: 200 } : {};
                            return (
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              <TableCell key={`row-heading-${productIndex}`} {...cellProps} component="th" width={200}>
                                {productIndex === 0 ? tableKey : ''}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                        <TableRow key={`row-value-${tableKey}`} className="value-row">
                          {displayProducts?.map((productDetail, idx) => {
                            const cellProps = idx === 0 ? { width: 200 } : {};
                            return (
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              <TableCell key={`row-heading-value-${idx}`} {...cellProps} width={200}>
                                {
                                 tableData[tableKey]?.[productDetail?.product?.itemId] || ''
                                }
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </React.Fragment>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          </>
        )}
      </QueryProvider>
    </>
  );
};

FBRTableView.displayName = 'FBRTableView';

FBRTableView.propTypes = {
  data: any,
  tableData: any,
  storeId: stringType,
  membershipInformation: any,
  onClick: any,
  onHover: any,
  currentItemIndex: numType,
  zipCode: stringType,
  itemId: stringType,
  recommendedFeatureSet: stringType,
  recommendedProductSet: stringType,
  scheme: stringType
};

FBRTableView.defaultProps = {
  data: null,
  tableData: null,
  storeId: '',
  scheme: '',
  membershipInformation: null,
  onClick: () => {},
  onHover: () => {},
  currentItemIndex: 0,
  zipCode: '',
  itemId: '',
  recommendedFeatureSet: '',
  recommendedProductSet: '',
};

export {
  FBRTableView
};